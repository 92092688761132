import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signin-cancel',
  templateUrl: './signin-cancel.component.html',
  styleUrls: ['./signin-cancel.component.scss']
})
export class SigninCancelComponent implements OnInit {
  @ViewChild('errorCancel', {static : true}) errorCancel:ElementRef;
  closeResult: string = '';
  content = 'errorCancel'
  constructor(private modalService: NgbModal) { }
  ngOnInit(): void {
    this.open(this.errorCancel);
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
