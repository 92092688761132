import { HttpClient } from '@angular/common/http';

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import {
  ChartType,
  ChartOptions,
  ChartDataSets,
  ChartConfiguration,
  ChartData,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Label, MultiDataSet, Color } from 'ng2-charts';
import { Subject, Subscription } from 'rxjs';
import { SharedServiceService } from 'src/app/shared-service.service';
import { builtInButtons } from '../data';

// graph-endpoint
const GRAPH_ENDPOINT_USERS = 'https://graph.microsoft.com/beta/reports/getCredentialUserRegistrationCount';
const GRAPH_ENDPOINT_MFA_COUNT =
  'https://graph.microsoft.com/beta/reports/authenticationMethods/usersRegisteredByFeature';
const GRAPH_ENDPOINT_MFA_PREMIUM =
  'https://graph.microsoft.com/v1.0/subscribedSkus';
const GRAPH_ENDPOINT_MFA = 'https://graph.microsoft.com/beta/users';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  // BarChart
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = [
    'MFA',
    '	Microsoft 365 Business Premium',
    'Microsoft 365 Business Standard',
    'Microsoft 365 Business Basic',
    'Unknown License',
  ];
  barChartLabelsSSPR: Label[] = [
    'SSPR Enabled Users',
    'SSPR Capable Users',
    'SSPR NOT Capable Users',
    
  ];
  2: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins: any = {
    backgroundColor: ['blue', '#FF6384', '#FFCE56', '#4BC0C0', '#36A2EB'],
  };
  // Users MFA
  totalUsers: number = 0;
  ssprEnabledUsers: number = 0;
  mfaEnabledUsers: number = 0;
  nonLicensedUsers: number = 0;
  // SSPR
  ssprCapablePercentageFind:any;
  ssprNotCapablePercentageFind:any;
  // bar chart test
  barChartOptions2: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {

      yAxes: [{
        ticks: {

          min: 0,
          max: 100,
          callback: function (value) { return value + "%" }
        },
        scaleLabel: {
          display: true,
          labelString: "Percentage"
        }
      }]
    },
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public barChartType: ChartType = 'bar';


  public barChartData23: ChartData = {
    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
      { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    ]
  };

  public barChartColors: Color[] = [{
    backgroundColor: ['#009343', '#F44337', 'orange', 'yellow', '#1976D2']
  }];
  barChartData2: ChartDataSets[] = [
    { data: [45, 37, 60, 70, 46, 100], label: 'Task Status' },
  ];
  barChartData: ChartDataSets[] = [{ data: [0, 0] }];
  ssprChartData: ChartDataSets[] = [{ data: [0, 0] }];

  // Variables
  usersCount: number;
  usersValue: number;
  currentScreen: boolean;
  isLoading = true;
  loading = true;
  profile: any;
  private readonly _destroying$ = new Subject<void>();

  numOfLicensedUsers: number = 0;
  numOfBusBasicUsers: number = 0;
  numOfBusStandUsers: number = 0;
  userCount: number = 0;

  skuid: any = [
    'efccb6f7-5641-4e0e-bd10-b4976e1bf68e',
    'e97c048c-37a4-45fb-ab50-922fbf07a370',
    'e823ca47-49c4-46b3-b38d-ca11d5abe3d2',
    'e2be619b-b125-455f-8660-fb503e431a5d',
    'd61d61cc-f992-433f-a577-5bd016037eeb',
    'cd2925a3-5076-4233-8931-638a8c94f773',
    'cbdc14ab-d96c-4c30-b9f4-6ada7cdc1d46',
    'ca9d1dd9-dfe9-4fef-b97c-9bc1ea3c3658',
    'c793db86-5237-494e-9b11-dcd4877c2c8c',
    'c42b9cae-ea4f-4ab7-9717-81576235ccac',
    'c2ac2ee4-9bb1-47e4-8541-d689c7e83371',
    'b05e124f-c7cc-45a0-a6aa-8cf78c946968',
    'aedfac18-56b8-45e3-969b-53edb4ba4952',
    '8a180c2b-f4cf-4d44-897c-3d32acc4a60b',
    '84a661c4-e949-4bd2-a560-ed7766fcaf2b',
    '81441ae1-0b31-4185-a6c0-32b6b84d419f',
    '7cfd9a2b-e110-4c39-bf20-c6a3f36a3121',
    '66b55226-6b4f-492c-910c-a3b7a3c9d993',
    '6070a4c8-34c6-4937-8dfb-39bbc6397a60',
    '50f60901-3181-4b75-8a2c-4c8e4c1d5a72',
    '4b590615-0888-425a-a965-b3bf7789848d',
    '46c119d4-0379-4a9d-85e4-97c66d3f909e',
    '44575883-256e-4a79-9da4-ebe9acabe2b2',
    '31d57bc7-3a05-4867-ab53-97a17835a411',
    '2a914830-d700-444a-b73c-e3f31980d833',
    '1aa94593-ca12-4254-a738-81a5972958e8',
    '18250162-5d87-4436-a834-d795c15c80f3',
    '078d2b04-f1bd-4111-bbd4-b4b1b354cef4',
    '06ebc4ee-1bb5-47dd-8120-11324bc54e06',
    '05e9a617-0261-4cee-bb44-138d3ef5d965',

  ];
  skuidBusBasic: any = [
    '3b555118-da6a-4418-894f-7df1e2096870',
    'dab7782a-93b1-4074-8bb1-0e61318bea0b'
  ];
  skuidBusStand: any = [
    'f245ecc8-75af-4f8e-b61f-27d8114de5f3',
    'ac5cef5d-921b-4f97-9ef3-c99076e5470f',
  ]
  // mfa-chart
  mfaChartData: MultiDataSet = [[0, 0]];
  mfaChartLabels: Label[] = ['', '', ''];
  mfaEnabledPercentage: string = '';
  mfaCapablePercentage: string = '';
  notMfaPercentage: string = '';
  ssprpercentage: number = 0;

  // BarCaht-MFA
  // sspr-chart
  // ssprChartData: MultiDataSet = [[0, 0]];
  ssprChartLabels: Label[] = ['', '', ''];
  ssprEnabledPercentage: string = '';
  ssprCapablePercentage: string = '';
  notSsprPercentage: string = '';

  mfaenabledPerc: any
  licensedUserPerc: any
  busStandUserPerc: any
  busBasUserPerc: any
  nonLicensedUserPerc: any
  translateSubscribe: Subscription;
  // steps-translation
  steptranslate: any = {};

  buttons = builtInButtons;
  doughnutChartType: ChartType = 'doughnut';
  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private sharedService: SharedServiceService,
    private shepherdService: ShepherdService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.shepherdService.cancel();
    this.currentScreen = this.sharedService.getValue();
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        if (result) {
          this.authService.instance.setActiveAccount(result.account);
        }
      },
      error: (error) => error,
    });
    this.getUsers();
  }
  ngAfterContentInit() {
    this.getTranslateNav();
  }
  ngAfterViewInit() { }

  addShepherd() {
    function dismissTour() {
      localStorage.setItem('shepherd-tour', 'yes');
    }

    this.shepherdService.addSteps([
      {
        id: 'step3',
        attachTo: {
          element: '.chart-one',
          on: 'auto',
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve: any) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 100);
          });
        },
        buttons: [builtInButtons.cancel, builtInButtons.next],
        classes: 'profile-class',
        highlightClass: 'highlight',
        scrollTo: true,
        title: this.steptranslate.title1,
        text: [
          `${this.steptranslate.text1} \n - ${this.mfaEnabledPercentage} ${this.steptranslate.text11} \n - ${this.licensedUserPerc - this.mfaenabledPerc}% ${this.steptranslate.text12} \n - ${this.busStandUserPerc}% ${this.steptranslate.text13} \n - ${this.busBasUserPerc}% ${this.steptranslate.text14}`,
        ],
        when: {
          show: () => { },
          hide: () => { },
        },
      },
      {
        id: 'step4',
        attachTo: {
          element: '.chart-two',
          on: 'auto',
        },
        beforeShowPromise: function () {
          return new Promise(function (resolve: any) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 100);
          });
        },
        buttons: [builtInButtons.cancel, builtInButtons.next],
        classes: 'profile-class',
        highlightClass: 'highlight',
        scrollTo: true,
        title: this.steptranslate.title2,
        text: [
          `${this.steptranslate.text2}\n - ${this.ssprEnabledPercentage} ${this.steptranslate.text21} \n - ${this.ssprCapablePercentage} ${this.steptranslate.text22} \n - ${this.notSsprPercentage} ${this.steptranslate.text23}`,
        ],
        when: {
          show: () => { },
          hide: () => { },
        },
      },
      {
        id: 'step5',
        attachTo: {
          element: '.menu-secureScore',
          on: 'bottom',
        },
        buttons: [builtInButtons.cancel],
        beforeShowPromise: function () {
          return new Promise(function (resolve: any) {
            setTimeout(function () {
              window.scrollTo(0, 0);
              resolve();
            }, 100);
          });
        },
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        title: this.steptranslate.title5,
        text: [this.steptranslate.text5],
        when: {
          show: () => { },
          hide: () => { },
        },
      },
    ]);
  }

  getTranslateNav() {
    this.translateSubscribe = this.translate
      .stream('button.exit')
      .subscribe((text: string) => {
        this.buttons.cancel.text = text;
      });
    this.translateSubscribe = this.translate
      .stream('button.next')
      .subscribe((text: string) => {
        this.buttons.next.text = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.title-3')
      .subscribe((text: string) => {
        this.steptranslate.title1 = text;
      });

    this.translateSubscribe = this.translate
      .stream('tour.text-3')
      .subscribe((text: string) => {
        this.steptranslate.text1 = text;
      });
      this.translateSubscribe = this.translate
      .stream('profile-chart.chart-1.subtitle.1')
      .subscribe((text: string) => {
        this.steptranslate.text11 = text;
      });
    this.translateSubscribe = this.translate
      .stream('profile-chart.chart-1.subtitle.2')
      .subscribe((text: string) => {
        this.steptranslate.text12 = text;
      });
      this.translateSubscribe = this.translate
      .stream('profile-chart.chart-1.subtitle.4')
      .subscribe((text: string) => {
        this.steptranslate.text13 = text;
      });
      this.translateSubscribe = this.translate
      .stream('profile-chart.chart-1.subtitle.5')
      .subscribe((text: string) => {
        this.steptranslate.text14 = text;
      });
      this.translateSubscribe = this.translate
      .stream('profile-chart.chart-1.subtitle.6')
      .subscribe((text: string) => {
        this.steptranslate.text15 = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.title-4')
      .subscribe((text: string) => {
        this.steptranslate.title2 = text;
      });

    this.translateSubscribe = this.translate
      .stream('tour.text-4')
      .subscribe((text: string) => {
        this.steptranslate.text2 = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.text-41')
      .subscribe((text: string) => {
        this.steptranslate.text21 = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.text-42')
      .subscribe((text: string) => {
        this.steptranslate.text22 = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.text-43')
      .subscribe((text: string) => {
        this.steptranslate.text23 = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.title-5')
      .subscribe((text: string) => {
        this.steptranslate.title5 = text;
      });
    this.translateSubscribe = this.translate
      .stream('tour.text-5')
      .subscribe((text: string) => {
        this.steptranslate.text5 = text;
      });

    this.translateSubscribe.unsubscribe;
  }

  getLicensedMethods() {
    this.http.get<any>(GRAPH_ENDPOINT_USERS).subscribe({
      next: (response) => {
        this.totalUsers = response.value[0].totalUserCount;
        this.ssprEnabledUsers = response.value[0].userRegistrationCounts.find(
          (registrationStatus: any) => registrationStatus.registrationStatus === 'registered'
        ).registrationCount;
        this.mfaEnabledUsers = response.value[0].userRegistrationCounts.find(
          (registrationStatus: any) => registrationStatus.registrationStatus === 'mfaRegistered'
        ).registrationCount;
      }
    })
  }
  findPercentage(num1) {
    return Math.floor((num1 / this.usersCount) * 100)
  }
  getMfaAuthenticationMethods() {
    this.getLicensedMethods();
    this.http.get<any>(GRAPH_ENDPOINT_MFA_COUNT).subscribe({
      next: (response) => {
        // Mfa-Count
        const ssprEnabledCount = response.userRegistrationFeatureCounts.find(
          (feature: any) => feature.feature === 'ssprEnabled'
        ).userCount;
        const ssprEnabledCount2 = response.userRegistrationFeatureCounts.find(
          (feature: any) => feature.feature === 'ssprEnabled'
        ).userCount;
        const ssprCapableCount = response.userRegistrationFeatureCounts.find(
          (feature: any) => feature.feature === 'ssprCapable'
        ).userCount;
        const mfaEnabledCount = response.userRegistrationFeatureCounts.find(
          (feature: any) => feature.feature === 'mfaCapable'
        ).userCount;
        const mfaCapableCount = this.numOfLicensedUsers;
        this.mfaenabledPerc = this.findPercentage(mfaEnabledCount),
          this.licensedUserPerc = this.findPercentage(this.numOfLicensedUsers),
          this.busStandUserPerc = this.findPercentage(this.numOfBusStandUsers),
          this.busBasUserPerc = this.findPercentage(this.numOfBusBasicUsers),
          this.nonLicensedUserPerc = this.findPercentage(this.nonLicensedUsers),
          this.mfaChartData = [
            [
              mfaEnabledCount,
              this.numOfLicensedUsers - mfaEnabledCount,
              mfaCapableCount < this.usersCount
                ? this.usersCount - mfaCapableCount
                : 0,
            ],
          ];
        const noOfStandardUsers = 5;
        const noOfBasicUSers = 5;
        this.barChartData = [
          {
            data: [
              this.mfaenabledPerc,
              this.licensedUserPerc - this.mfaenabledPerc,
              this.busStandUserPerc,
              this.busBasUserPerc,
              this.nonLicensedUserPerc,
              this.mfaChartData,
              100,
            ],
          },
        ];
        this.ssprpercentage = this.findPercentage(ssprEnabledCount)
        this.ssprCapablePercentageFind = this.findPercentage(ssprCapableCount)
        this.ssprNotCapablePercentageFind = this.findPercentage(this.usersCount - (ssprCapableCount + ssprEnabledCount))


        //MFA-Count
        this.mfaEnabledPercentage =
          ((mfaEnabledCount / this.usersCount) * 100).toFixed().toString() +
          '%';
        this.mfaCapablePercentage =
          ((mfaCapableCount / this.usersCount) * 100).toFixed().toString() +
          '%';
        const notMfaUsers = this.usersCount - this.numOfLicensedUsers;
        this.notMfaPercentage =
          ((notMfaUsers / this.usersCount) * 100).toFixed().toString() + '%';

        // SSPR-Count
        this.ssprChartData = [
          {
            data: [
              this.findPercentage(ssprEnabledCount),
              this.findPercentage(ssprCapableCount),
              this.findPercentage(this.usersCount - (ssprCapableCount + ssprEnabledCount)),
              100
            ]
          },
        ];

        // this.ssprChartData = [
        //   [
        //     ssprCapableCount,
        //     ssprEnabledCount,
        //     this.usersCount - (ssprCapableCount + ssprEnabledCount),
        //   ],
        // ];

        this.ssprEnabledPercentage =
          ((ssprEnabledCount / this.usersCount) * 100).toFixed().toString() +
          '%';
        this.ssprCapablePercentage =
          ((ssprCapableCount / this.usersCount) * 100).toFixed().toString() +
          '%';
        this.notSsprPercentage =
          (
            ((this.usersCount - (ssprEnabledCount + ssprCapableCount)) /
              this.usersCount) *
            100
          )
            .toFixed()
            .toString() + '%';
        
        setTimeout(() => {
          this.addShepherd();
        }, 100);
        setTimeout(() => {
          this.tourStart();
        }, 150);
      },
      error: (error) => this.loadingTime(),
    });
  }

  tourStart() {
    if (localStorage.getItem('shepherd-tour') == 'no') {
      this.shepherdService.start();
    }
  }

  loadingTime() {
    this.getUsers();
  }

  getUsers() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA).subscribe({
      next: (response) => {
        this.usersCount = response.value.length;
        // this.numOfLicensedUsers = this.getLicensedUsersCount(this.usersValue);
        this.getPremiumUsers();
        this.getMfaAuthenticationMethods();
      },
      error: (error) => error,
    });
  }

  getPremiumUsers() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA_PREMIUM).subscribe({
      next: (response) => {
        this.usersValue = response.value;
        this.numOfLicensedUsers = this.getLicensedUsersCount(this.usersValue);
        this.numOfBusBasicUsers = this.getBusBasicUsers(this.usersValue);
        this.numOfBusStandUsers = this.getBusStandUsers(this.usersValue);
        this.unlicensedUsers();
      },
      error: (error) => error,
    });
  }
  unlicensedUsers() {
    this.nonLicensedUsers = this.usersCount - this.numOfLicensedUsers + this.numOfBusBasicUsers + this.numOfBusStandUsers;
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
   
  }
  getBusBasicUsers(users: any): number {
    let count: number = 0;
    this.skuidBusBasic.forEach((skuid) => {
      users.forEach((plan) => {
        if (plan.skuId === skuid) {
          if (plan.capabilityStatus === 'Enabled') {
            count = count + plan.consumedUnits;
          }
        }
      })
    });
    return count
  };
  getBusStandUsers(users: any): number {
    let count: number = 0;
    this.skuidBusStand.forEach((skuid) => {
      users.forEach((plan) => {
        if (plan.skuId === skuid) {
          if (plan.capabilityStatus === 'Enabled') {
            count = count + plan.consumedUnits;
          }
        }
      })
    });
    return count
  };
  getLicensedUsersCount(users: any): number {
    let count: number = 0;
    this.skuid.forEach((skuid) => {
      users.forEach((plan) => {
        if (plan.skuId === skuid) {
          if (plan.capabilityStatus === 'Enabled') {
            count = count + plan.consumedUnits;

          }
        }
      });
    });
    return count;
  }



  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.shepherdService.cancel();
  }
}
