<div class="container p-0">
  <div class="loading-div " *ngIf="isLoading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

<div>
  <div class="row mt-4" *ngIf="!isLoading">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <h3 class="fw-bolder">{{ 'price.head' | translate }}</h3>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-12">
      <label class="toggle d-flex justify-content-center mt-4 mb-2" for="initialID">
        <input type="checkbox" class="toggle__input" id="initialID"  #saveInitial [checked]="isInitialChecked"
        (change)="onCheckInitial(saveInitial.checked)"/>
        <span class="toggle-track">
          <span class="toggle-indicator">
            <span class="checkMark">
              <svg viewBox="0 0 24 24" id="ghq-svg-check" role="presentation" aria-hidden="true">
                <path d="M9.86 18a1 1 0 01-.73-.32l-4.86-5.17a1.001 1.001 0 011.46-1.37l4.12 4.39 8.41-9.2a1 1 0 111.48 1.34l-9.14 10a1 1 0 01-.73.33h-.01z"></path>
              </svg>
            </span>
          </span>
        </span>
        Need Initial Setup ?
      </label>
    </div> -->
  </div>
  <div class="mt-4" [ngClass]="{'scroll' : currentScreen}">
    <div class="row">
      <div class="col-12 d-flex justify-content-center col-md-6">
        <div class="pricingtable">
          <div class="top">
            <h2>{{ 'price.monthly' | translate }}</h2>
          </div>
          <ul>
            <li><strong>{{ 'price.no-of-users' | translate }}</strong> {{numOfLicensedUsers}}</li>
            <li><strong>{{ 'price.per-user' | translate }}</strong> 5 CHF</li>
            <!-- <li><strong>Initial Service</strong> {{initialAmt}}/CHF</li> -->
            <!-- <li><strong>Monthly Service</strong> Free</li> -->
            <li><strong>Total CHF</strong> {{perUserMon}} CHF</li>
          </ul>

          <hr />

          <h1><sup>CHF</sup>{{perUserMon}}</h1>
          <p>{{ 'price.per-month' | translate }}</p>

          <a class="text-uppercase" href="#">{{ 'price.signup' | translate }}</a>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center col-md-6">
        <div class="pricingtable">
          <div class="top">
            <h2>{{ 'price.yearly' | translate }}</h2>
          </div>
          <ul>
            <li><strong>{{ 'price.no-of-users' | translate }}</strong> {{numOfLicensedUsers}}</li>
            <li><strong>{{ 'price.per-user' | translate }}</strong> 50 CHF</li>
            <!-- <li><strong>Initial Service</strong> {{initialAmt}}/CHF</li> -->
            <!-- <li><strong>Monthly Service</strong> Free</li> -->
            <li><strong>Total CHF</strong> {{perUserAnu}} CHF</li>
          </ul>

          <hr />

          <h1><sup>CHF</sup>{{perUserAnu}}</h1>
          <p>{{ 'price.per-year' | translate }}</p>

          <a class="text-uppercase" href="#">{{ 'price.signup' | translate }}</a>
      </div>
      </div>
    </div>
  </div>
</div>
</div>
