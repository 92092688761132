<div class="wrapper">
  <app-signin-cancel *ngIf="!adminConsent && !consentTrue"></app-signin-cancel>
  <app-signin-not-admin *ngIf="adminConsent && !consentTrue"></app-signin-not-admin>
  <div class="row h-100vh">
    <div class="col-lg-6  col-md-6 col-sm-12 my-auto">
      <div class="ms-4">
        <div class="head">
          <h1 class="topic">{{ 'consent.head' | translate }}</h1>
          <p>{{ 'consent.body' | translate }}</p>
          <ul>
            <li>{{ 'consent.body-sub.sub-1' | translate }}</li>
            <li>{{ 'consent.body-sub.sub-2' | translate }}</li>
            <li>{{ 'consent.body-sub.sub-3' | translate }}</li>
          </ul>
          <!-- <p>{{ 'consent.body.sub2' | translate }}</p> -->
          <p class="fw-bold">{{ 'consent.body-sub3' | translate }}</p>
        </div>
        <div class="form-check mb-2">
          <input class="form-check-input" (change)="checkCheckBoxvalue($event)" type="checkbox"  id="flexCheckChecked">
          <label class="form-check-label" for="flexCheckChecked">
            {{ 'consent.checkbox' | translate }}
          </label>
        </div>
          <a *ngIf="appPermission" class="btn  btn-success btn-lg box-shadow--4dp" href="https://login.microsoftonline.com/common/adminconsent?client_id={{appId}}&redirect_uri={{redirectUrl}}">{{ 'consent.button' | translate }}<svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
            <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
          </svg></a>
          <a *ngIf="!appPermission" class="btn  btn-success btn-lg box-shadow--4dp disabled-btn"> {{ 'consent.button' | translate }}<svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
            <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
          </svg></a>
      </div>
    </div>
    <div class="col-md-6 side-tour my-auto" *ngIf="!responsiveDom">
      <div class="text-center">
        <video autoplay loop muted >
          <source *ngIf="translate" src="{{consentGifDE}}" type="video/webm" />
          <source *ngIf="!translate" src="{{consentGifEN}}" type="video/webm" />

          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  </div>
</div>



<!-- <h1>Angular 13 Bootstrap Modal Popup Example - Laratutorials.com </h1>

<button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">Open My Modal</button>

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    This is example from Tutsmake.com
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template> -->


