// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  AppID:"28e40970-e61c-41ce-be09-722a09064be3",
  RedirectAppUri:"https://securescore.evolve365.cloud/profile",
  // RedirectAppUriError:"http://localhost:4200/consent"
  gif: {
    german:{
      consentGif:"../assets/media/consent_DE.webm",
    },
    english:{
      consentGif:"../assets/media/consent_EN.webm",
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
