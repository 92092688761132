import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import {  Subscription } from 'rxjs';
import { SharedServiceService } from 'src/app/shared-service.service';
import { builtInButtons } from '../data';

const GRAPH_ENDPOINT_MFA_COUNT = "https://graph.microsoft.com/v1.0/security/secureScores"
@Component({
  selector: 'app-secure-score',
  templateUrl: './secure-score.component.html',
  styleUrls: ['./secure-score.component.scss']
})
export class SecureScoreComponent implements OnInit, OnDestroy {
  currentScreen:boolean;
  percent:number = 83.33
  translateSubscribe: Subscription

  steptranslate:any = {}
  // icon value
  pass = "../../.././../assets/svg/pass.svg";
  fail = "../../.././../assets/svg/fail.svg";
  attection = "../../.././../assets/svg/attention.svg";
  isLoading: boolean = true;
  secureScores:any =[];
  sstranslate:any[] = [
    {},{},{},{},{},{},{},{}
  ]


buttons = builtInButtons;

  percentToColor(percent:number): string {
    if (percent < 1.00) {
      return "#fe5454"; //red
    } else if (percent < 50.00) {
      return "#ffa600"; // orange
    } else if (percent >= 99) {
      return "#00FF00";// green
    }
    return "#ffa600"; //orrange
  }

  constructor(private sharedService: SharedServiceService, private http:HttpClient, private shepherdService: ShepherdService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getSSTranslate();
    this.currentScreen = this.sharedService.currentScreen;
    this.getSecureScore();
  }
  ngAfterContentInit(){
    this.getTranslateNav()
      }
  ngAfterViewInit() {

    setTimeout(() => {
      this.addShepherd ();
  }, 200);
}

getSSTranslate() {
  this.translateSubscribe = this.translate
      .stream('button.exit')
      .subscribe((text: string) => {
        this.buttons.cancel.text = text;
      });
      this.translateSubscribe = this.translate
      .stream('button.next')
      .subscribe((text: string) => {
        this.buttons.next.text = text;
      });
  this.translateSubscribe = this.translate
    .stream("secure-score.title.mfa-for-admin")
    .subscribe((text: string) => {
      this.sstranslate[0].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.mfa-for-admin")
    .subscribe((text: string) => {
      this.sstranslate[0].text = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.title.multiple-admins")
    .subscribe((text: string) => {
      this.sstranslate[1].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.multiple-admins")
    .subscribe((text: string) => {
      this.sstranslate[1].text = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.title.limited-admin")
    .subscribe((text: string) => {
      this.sstranslate[2].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.limited-admin")
    .subscribe((text: string) => {
      this.sstranslate[2].text = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.title.block-legacy")
    .subscribe((text: string) => {
      this.sstranslate[3].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.block-legacy")
    .subscribe((text: string) => {
      this.sstranslate[3].text = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.title.require-mfa")
    .subscribe((text: string) => {
      this.sstranslate[4].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.require-mfa")
    .subscribe((text: string) => {
      this.sstranslate[4].text = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.title.enable-sspr")
    .subscribe((text: string) => {
      this.sstranslate[5].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.enable-sspr")
    .subscribe((text: string) => {
      this.sstranslate[5].text = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.title.disable-encryption")
    .subscribe((text: string) => {
      this.sstranslate[6].title = text;
    });
    this.translateSubscribe = this.translate
    .stream("secure-score.description.disable-encryption")
    .subscribe((text: string) => {
      this.sstranslate[6].text = text;
    });
}

getTranslateNav() {
  this.translateSubscribe = this.translate
    .stream("tour.title-6")
    .subscribe((text: string) => {
      this.steptranslate.title1 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-6")
    .subscribe((text: string) => {
      this.steptranslate.text1 = text;
    });
  this.translateSubscribe = this.translate
    .stream("tour.title-7")
    .subscribe((text: string) => {
      this.steptranslate.title2 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-7")
    .subscribe((text: string) => {
      this.steptranslate.text2 = text;
    });
  this.translateSubscribe = this.translate
    .stream("tour.title-8")
    .subscribe((text: string) => {
      this.steptranslate.title3 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-8")
    .subscribe((text: string) => {
      this.steptranslate.text3 = text;
    });
  this.translateSubscribe = this.translate
    .stream("tour.title-9")
    .subscribe((text: string) => {
      this.steptranslate.title4 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-9")
    .subscribe((text: string) => {
      this.steptranslate.text4 = text;
    });
  this.translateSubscribe = this.translate
    .stream("tour.title-10")
    .subscribe((text: string) => {
      this.steptranslate.title5 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-10")
    .subscribe((text: string) => {
      this.steptranslate.text5 = text;
    });
  this.translateSubscribe = this.translate
    .stream("tour.title-11")
    .subscribe((text: string) => {
      this.steptranslate.title6 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-11")
    .subscribe((text: string) => {
      this.steptranslate.text6 = text;
    });
  this.translateSubscribe = this.translate
    .stream("tour.title-12")
    .subscribe((text: string) => {
      this.steptranslate.title7 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-12")
    .subscribe((text: string) => {
      this.steptranslate.text7 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.title-13")
    .subscribe((text: string) => {
      this.steptranslate.title8 = text;
    });
    this.translateSubscribe = this.translate
    .stream("tour.text-13")
    .subscribe((text: string) => {
      this.steptranslate.text8 = text;
    });
  }
  checkScore(){
    this.shepherdService.show('step14');
  }
  addShepherd() {
    function dismissTour() {
      localStorage.setItem('shepherd-tour', 'yes');
    }

    this.shepherdService.addSteps([
      {
        id: 'step7',
        attachTo: {
          element: '.element0',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element0') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title1,
        text: [this.steptranslate.text1],
      },
      {
        id: 'step8',
        attachTo: {
          element: '.element1',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element1') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title2,
        text: [this.steptranslate.text2],
      },
      {
        id: 'step9',
        attachTo: {
          element: '.element2',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element2') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title3,
        text: [this.steptranslate.text3],
      },
      {
        id: 'step10',
        attachTo: {
          element: '.element3',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element3') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title4,
        text: [this.steptranslate.text4],
      },
      {
        id: 'step11',
        attachTo: {
          element: '.element4',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element4') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title5,
        text: [this.steptranslate.text5],
      },
      {
        id: 'step12',
        attachTo: {
          element: '.element5',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element5') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title6,
        text: [this.steptranslate.text6],
      },
      {
        id: 'step13',
        attachTo: {
          element: '.element6',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel,builtInButtons.next],
        showOn: function () {
          return document.querySelector('.element6') ? true : false;
      },
        scrollTo: true,
        title: this.steptranslate.title7,
        text: [this.steptranslate.text7],
      },
      {
        id: 'step14',
        attachTo: {
          element: '.menu-price',
          on: 'auto'
        },
        buttons: [builtInButtons.cancel],
        scrollTo: true,
        title: this.steptranslate.title8,
        text: [this.steptranslate.text8],
      }
    ]);


  }

  findControl(controlMfa, index:number, response){
    const controlMfas = response.value[0].controlScores.find(
      (mfa:any) =>
      mfa.controlName === controlMfa,
    )

if(controlMfas == undefined ){
return null
}
else{
  this.secureScores[index] = controlMfas;
  this.secureScores[index].title = this.sstranslate[index].title
  this.secureScores[index].detail = this.sstranslate[index].text
}
}

  getSecureScore() {
    this.http.get<any>(GRAPH_ENDPOINT_MFA_COUNT).subscribe({
      next: (response) => {
          this.findControl("AdminMFAV2",0, response)
          this.findControl("OneAdmin",1,response)
          this.findControl("RoleOverlap",2,response)
          this.findControl("BlockLegacyAuthentication",3,response)
          this.findControl("MFARegistrationV2",4,response)
          this.findControl("SelfServicePasswordReset",5,response)
          this.findControl("TLSDeprecation",6,response)
          this.isLoading = false;
          setTimeout(() => {
            this.tourStart();
          }, 100);



      },
      error: (error) =>
        this.isLoading = true
    })

  }

  tourStart(){
    if (localStorage.getItem('shepherd-tour') == 'no') {
      this.shepherdService.start();
    }
  }
  ngOnDestroy(): void {
    this.shepherdService.cancel()

  }
}

