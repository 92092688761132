import { EventEmitter, HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  screenWidth;
  currentScreen: boolean;
  isLoggedIn: boolean
  private baseDom: BehaviorSubject<boolean>;

  paginator = new EventEmitter<number>();
  constructor() {
    this.onResize()

   }

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenWidth = window.innerWidth;
      this.currentScreen = this.screenWidth <=  768;
  }

  getValue(){
    return this.currentScreen
  }
  getisLogged(){
    return this.currentScreen
  }
  setisLogged(){
    this.isLoggedIn = true
  }
  setValue(newValue?): void {
    this.baseDom = new BehaviorSubject<boolean>(this.currentScreen);
  }
  onChangePage(page:any) {
    this.paginator.emit(page);
  }

  getPage() {
    return this.paginator;
  }
}
