<div class=" ">
  <div class="row h-100vh">
    <div class="col-lg-6  col-md-6 col-sm-12 my-auto">
      <div class="ms-4">
        <p class="lead text-muted">Evolve Now presents</p>
      <div class="tile">
        <h1 class="head">EVOLVE365</h1>
      </div>
      <div class="sub-title">
        <h1  class="head">Secure Score</h1>
      </div>
      <div class="start-button">
        <button class="btn  btn-success btn-lg box-shadow--4dp" routerLink="/consent" (click)="letsGo()">{{ 'welcome.start' | translate }} <svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" fill="currentColor" class="bi bi-caret-right" viewBox="0 0 16 16">
          <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
        </svg></button>
      </div>
      </div>
    </div>
    <div class="col-md-6"  *ngIf="!responsiveDom">
      <div class="bg-welcome text-center">
        <div class="welcome-img" >
          <!-- <img class="child" src="../../../assets/image/Microsoft_Defender_2020_Fluent_Design_icon.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
</div>

