<div class="container p-0 second-element">
  <div class="loading-div" *ngIf="isLoading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="main-div" *ngIf="!isLoading">
    <div class="row .scroll">
      <div
        class="col-12 d-flex align-items-center justify-content-center mt-2 mb-2"
      >
        <h3 class="fw-bolder">Security Overview</h3>
      </div>
    </div>
    <div id="chart" [ngClass]="{ scroll: currentScreen }">
      <div class="row scroll">
        <div class="col-12 my-auto">
          <div class="col-12">
            <div class="m-4">
              <div class="card mx-auto">
                <h5 class="card-title mx-auto p-2">
                  {{ "profile-chart.chart-1.head" | translate }} 
                  <span class="d-block text-center fw-bolder mt-4">{{"profile-chart.chart-1.text" | translate  }} {{totalUsers}}</span>
                </h5>
                
                <div class="row">
                  <div class="col-md-12 mx-auto my-auto p-4">
                    <div class="chart-wrapper mx-auto ">
                      <canvas
                        baseChart
                        [datasets]="barChartData"
                        [labels]="barChartLabels"
                        [options]="barChartOptions2"
                        [plugins]="barChartPlugins"
                        [legend]="false"
                        [colors]="barChartColors"
                        [chartType]="barChartType"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="col-md-12 my-auto cardBody">
                    <div class="card-body offset-md-2">
                      <div class="text-muted card-text">
                        <div
                          class="doughnut-description chart-table"
                          *ngIf="mfaCapablePercentage !== ''"
                        >
                          <span class="one"
                            >{{mfaEnabledPercentage}}
                            {{
                              "profile-chart.chart-1.subtitle.1" | translate
                            }}</span
                          >
                          <span class="two"
                            >{{ licensedUserPerc - mfaenabledPerc}}%
                            {{
                              "profile-chart.chart-1.subtitle.2" | translate
                            }}</span
                          >
                          <span class="three"
                            >{{ busStandUserPerc }}%
                            {{
                              "profile-chart.chart-1.subtitle.4" | translate
                            }}</span
                          >
                          <span class="four"
                            >{{busBasUserPerc}}%
                            {{
                              "profile-chart.chart-1.subtitle.5" | translate
                            }}</span>
                          <span class="five"
                            >{{ nonLicensedUserPerc }}%
                            {{
                              "profile-chart.chart-1.subtitle.6" | translate
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="row">
                  <div class="col-md-12 mx-auto my-auto p-4">
                    <div class="chart-wrapper mx-auto ">
                      <canvas
                        baseChart
                        [datasets]="ssprChartData"
                        [labels]="barChartLabelsSSPR"
                        [options]="barChartOptions2"
                        [plugins]="barChartPlugins"
                        [legend]="false"
                        [colors]="barChartColors"
                        [chartType]="barChartType"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="col-md-12 my-auto cardBody">
                    <div class="card-body offset-md-2">
                      <div class="text-muted card-text">
                        <div
                          class="doughnut-description chart-table"
                          *ngIf="ssprEnabledPercentage !== ''"
                        >
                          <span class="one"
                            >{{ ssprpercentage }}%
                            {{
                              "profile-chart.chart-2.subtitle.1" | translate
                            }}</span
                          >
                          <span class="two"
                            >{{ ssprCapablePercentage }}
                            {{
                              "profile-chart.chart-2.subtitle.2" | translate
                            }}</span
                          >
                          <span class="three"
                            >{{ notSsprPercentage }}
                            {{
                              "profile-chart.chart-2.subtitle.3" | translate
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
