import { Component, OnInit } from '@angular/core';
import { SharedServiceService } from 'src/app/shared-service.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  responsiveDom: boolean;
  constructor( private sharedService: SharedServiceService) {
    this.sharedService.onChangePage(0);

   }
  //  GoogleAnalytics track click
   letsGo(){
    gtag('event', 'Lets go event', {
      'event_category': 'BUTTON_CLICK',
      'event_label': 'Track Me Click',
      'value': 'Lets go event was clicked'   })
  }

  ngOnInit(): void {
      this.responsiveDom = this.sharedService.getValue();
  }

}
