import { HttpClient } from '@angular/common/http';
import { AfterContentInit, AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
} from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ConsentErrorService } from 'src/app/services/consent-error.service';


import { SharedServiceService } from 'src/app/shared-service.service';
import { builtInButtons } from './data';

// graph_endpoint
const GRAPH_ENDPOINT_ME = 'https://graph.microsoft.com/v1.0/me';
const GRAPH_ENDPOINT_MFA = 'https://graph.microsoft.com/beta/users';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  usersCount: number = 0;
  usersValue: any;
  tabIndex = 0;
  isIframe = false;
  loginDisplay = false;
  adminConsent: boolean = false;
  error = false;

  marketPlaceUrl = 'https://appsource.microsoft.com/en-us/product/web-apps/evolvenowgmbh1593800869666.evolve365_security'
  translateSubscribe: Subscription;
  // steps-translation
  steptranslate:any = {}
  buttons = builtInButtons;


  popperOptions: {
    modifiers: [{ name: 'offset'; options: { offset: [0, 12] } }];
  };

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private sharedService: SharedServiceService,
    private shepherdService: ShepherdService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private consent_error: ConsentErrorService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.sharedService.onChangePage(2);
  }
  action() {
    if (localStorage.getItem('shepherd-tour') == 'no') {
      this.shepherdService.start();
    this.shepherdService.show('step6');
    }
  }
  actionPrice() {
    if (localStorage.getItem('shepherd-tour') == 'no') {
      this.shepherdService.start();
    this.shepherdService.show('step6');
    }
  }
  ngAfterContentInit(){
this.getTranslateNav()
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.loginDisplay) {
        this.shepherdService.cancel();
      }else{
        setTimeout(() => {
          if(this.error == false){
            this.addShepherd ();
            this.tourStart()
          }else{
            this.shepherdService.cancel();
          }

      }, 500);

      }
  }, 1000);



  }
  onTour(){
    localStorage.setItem('shepherd-tour', 'no');
    window.location.reload();
  }

  tourStart(){
    if (localStorage.getItem('shepherd-tour') == 'no') {
      this.shepherdService.start();
    }
  }

addShepherd (){
  function dismissTour() {
    localStorage.setItem('shepherd-tour', 'yes');
  }
  this.shepherdService.addSteps([
    {
      id: 'step1',
      attachTo: {
        element: '',
        on: 'bottom',
      },
      buttons: [builtInButtons.cancel, builtInButtons.next],

      classes: 'shepherd-class-mine',
      highlightClass: 'highlight',
      scrollTo: false,
      title: this.steptranslate.title1,
      text: [this.steptranslate.text1],
    },
    {
      id: 'step2',
      attachTo: {
        element: '.first-element',
        on: 'bottom',
      },
      buttons: [
        builtInButtons.cancel
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      title: this.steptranslate.title2,
      text: [this.steptranslate.text2],
      when: {
        show: () => {},
        hide: () => {},

      },
    },
  ]);
}

dismissTour() {
  localStorage.setItem('shepherd-tour', 'yes');
  this.shepherdService.cancel()
}

  getTranslateNav() {
    this.translateSubscribe = this.translate
      .stream('button.exit')
      .subscribe((text: string) => {
        this.buttons.cancel.text = text;
      });
      this.translateSubscribe = this.translate
      .stream('button.next')
      .subscribe((text: string) => {
        this.buttons.next.text = text;
      });
    this.translateSubscribe = this.translate
      .stream("tour.title-1")
      .subscribe((text: string) => {
        this.steptranslate.title1 = text;
      });

      this.translateSubscribe = this.translate
      .stream("tour.text-1")
      .subscribe((text: string) => {
        this.steptranslate.text1 = text;
      });
      this.translateSubscribe = this.translate
      .stream("tour.title-2")
      .subscribe((text: string) => {
        this.steptranslate.title2 = text;

      });
      this.translateSubscribe = this.translate
      .stream("tour.text-2")
      .subscribe((text: string) => {
        this.steptranslate.text2 = text;
      });
    this.translateSubscribe.unsubscribe;
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.route.queryParams
    .subscribe(params => {
      const admin_consent = params['admin_consent'];
      const error = params['error'];
      const error_description = params['error_description'];
      const error_subcode = params['error_subcode']
      if(admin_consent === 'True' && error == undefined){
        this.consent_error.setAdmin(null)
      }if(error == 'access_denied') {
        if(error_subcode == 'cancel'){
          this.error = true;
          this.consent_error.setAdmin(true)
          this.consent_error.setconsent(false)
          this.router.navigate(['consent'])
        }if(error_description !== undefined){
          this.error = true;
          this.consent_error.setAdmin(false)
          this.consent_error.setconsent(false)
          this.router.navigate(['consent'])
        }
      }
    }
  );

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  login() {
    // if (this.msalGuardConfig.authRequest) {
    //   this.authService
    //     .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //     .subscribe((response: AuthenticationResult) => {
    //       this.authService.instance.setActiveAccount(response.account);
    //       this.sharedService.setisLogged();
    //       if (this.loginDisplay) {
    //         this.shepherdService.complete();
    //       }

    //        this.getUsers();

    //     });

    // }
    gtag('event', 'Login event', {
      'event_category': 'BUTTON_CLICK',
      'event_label': 'Track Me Click',
      'value': 'Login event was clicked'   })
    this.authService.loginRedirect().subscribe(()=>{
      this.sharedService.setisLogged();
      this.shepherdService.cancel();
           this.getUsers();
    });
  }

  getUsers() {

    this.http.get<any>(GRAPH_ENDPOINT_MFA).subscribe({
      next: (response) => {
        this.usersCount = response.value.length;
        this.usersValue = response.value;
        this.shepherdService.complete();
      }
    });
  }

  logout() {
    this.authService.logoutRedirect();
  }

  onTabClick(index) {
    this.tabIndex = index;
    if(index === 2){
      gtag('event', 'Price button event', {
        'event_category': 'BUTTON_CLICK',
        'event_label': 'Track Me Click',
        'value': 'Price button event was clicked'   })
    }
    
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();

  }
}
