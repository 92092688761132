import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConsentErrorService } from 'src/app/services/consent-error.service';
import { SharedServiceService } from 'src/app/shared-service.service';
import { environment } from 'src/environments/environment';
declare var window: any;

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  consentGifDE = environment.gif.german.consentGif
  consentGifEN = environment.gif.english.consentGif
  closeResult: string = '';

  responsiveDom: boolean;
  redirectUrl = environment.RedirectAppUri;
  appId = environment.AppID;
  private routerInfo: BehaviorSubject<boolean>;
  translate:boolean;
  adminConsent:boolean = null;
  consentTrue = true
  appPermission: boolean = false
  constructor(private sharedService: SharedServiceService, private route: ActivatedRoute, private consentError: ConsentErrorService, private modalService: NgbModal) {
    this.getTranslate()
    this.routerInfo = new BehaviorSubject<boolean>(false);
    this.sharedService.onChangePage(1);
  }
   // check-box
   getCheckbox(): Observable<boolean> {
    return this.routerInfo.asObservable();
  }
  setCheckbox(newValue): void {
    this.routerInfo.next(newValue);
  }

  getTranslate() {
    if (localStorage.getItem('language') == 'de') {
      this.translate = true}
      else{
        this.translate = false
      }
    }

   checkCheckBoxvalue(event) {
    let checked = event.target.checked;
    if (checked) {
      this.setCheckbox(true);
    } else {
      this.setCheckbox(false);
    }
  }

  ngOnInit(): void {
    this.consentError.getAdmin().subscribe((value) => {
      this.adminConsent = value;
    });
    this.consentError.getconsent().subscribe((value) => {
      this.consentTrue = value;
    });


    this.getCheckbox().subscribe((value) => {
      this.appPermission = value
    });
    this.responsiveDom = this.sharedService.getValue();
  }
  open(content:any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
