<div class="px-5">
  <div class="loading-div" *ngIf="isLoading">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <h3 class="fw-bolder">Security Check</h3>
    </div>
  </div>
  <div *ngIf="!isLoading">
    <div class="row scroll">
      <div *ngFor="let score of secureScores; index as i;">
        <div class="col-12 mb-2">
          <div class="card" style="max-width: 100%;" >
            <div class="row g-0">
              <div class="col-md-2 col-4 d-flex align-items-center justify-content-center" style="min-height:10%">
                  <img  *ngIf="score.scoreInPercentage >= 100"
                    src="../../.././../assets/svg/pass.svg"
                  />
                  <img *ngIf="score.scoreInPercentage <= 1"
                    src="../../.././../assets/svg/fail.svg"
                  />
                  <img *ngIf="score.scoreInPercentage > 1 && score.scoreInPercentage < 50"
                    src="../../.././../assets/svg/attention.svg"
                  />
              </div>
              <div class="col-md-4 col-8 d-flex align-items-center "  [class]="'element'+i" style="min-height:10%">
                <h5 class="card-title">{{score.title}}</h5>
              </div>
              <div class="col-md-6 col-12">
                <div class="card-body">
                  <p class="card-text">{{score.detail}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
