import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsentErrorService {
  private admin_error: BehaviorSubject<boolean>;
  private consent_error: BehaviorSubject<boolean>;
  constructor() {
    this.admin_error = new BehaviorSubject<boolean>(false);
    this.consent_error = new BehaviorSubject<boolean>(true);
  }

  getAdmin(): Observable<boolean> {
    return this.admin_error.asObservable();
  }
  setAdmin(newValue): void {
    this.admin_error.next(newValue);
  }
  getconsent(): Observable<boolean> {
    return this.consent_error.asObservable();
  }
  setconsent(newValue): void {
    this.consent_error.next(newValue);
  }
}
