import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConsentComponent } from './component/consent/consent.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { ProfileComponent } from './component/profile/profile.component';
import { FormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCircleProgressModule } from 'ng-circle-progress';

// import MSAL
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { loginRequest, msalConfig } from './auth.config';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { ProfileDetailComponent } from './component/profile/profile-detail/profile-detail.component';
import { SecureScoreComponent } from './component/profile/secure-score/secure-score.component';
import { PriceDetailComponent } from './component/profile/price-detail/price-detail.component';
import { SigninCancelComponent } from './component/consent/consent-error/signin-cance/signin-cancel.component';
import { SigninNotAdminComponent } from './component/consent/consent-error/signin-not-admin/signin-not-admin.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json');
}

// MSAL Functions
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    'https://graph.microsoft.com/v1.0/security/secureScores',
    ['SecurityEvents.Read.All']
  );
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'Directory.Read.All',
  ]);
  protectedResourceMap.set('https://graph.microsoft.com/beta/users', [
    'Directory.Read.All',
  ]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/subscribedSkus', [
    'Directory.Read.All',
  ]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/organization', [
    'Directory.Read.All',
  ]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', [
    'Directory.Read.All',
  ]);
  protectedResourceMap.set(
    'https://graph.microsoft.com/beta/reports/authenticationMethods/usersRegisteredByFeature',
    ['AuditLog.Read.All']
  );
  protectedResourceMap.set(
    'https://graph.microsoft.com/beta/reports/getCredentialUserRegistrationCount',
    ['Reports.Read.All']
  );

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ConsentComponent,
    WelcomeComponent,
    ProfileComponent,
    ProfileDetailComponent,
    SecureScoreComponent,
    PriceDetailComponent,
    SigninCancelComponent,
    SigninNotAdminComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    ChartsModule,
    MsalModule,
    NgCircleProgressModule.forRoot({
      toFixed: 2,
      showSubtitle: false,
      animationDuration: 300,
      animation: true,
      radius: 50,
      outerStrokeWidth: 4,
      innerStrokeWidth: 1,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    // MSAL Providers,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent,  MsalRedirectComponent],
})
export class AppModule {}
