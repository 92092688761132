<div class="wrapper">
 <div class="row">
   <div class="col-fixed-head d-flex align-items-center justify-content-end">
    <div class="login ">
      <button class=" btn btn-success float-end btn-sm first-element" *ngIf="!loginDisplay" (click)="login()">LOGIN</button>
      <button class=" btn btn-success float-end btn-sm" *ngIf="loginDisplay" (click)="logout()">LOGOUT</button>
      <a class=" btn btn-success float-end me-2 btn-sm"  href="{{marketPlaceUrl}}" target="_blank">SIGN UP</a>
      <a (click)="onTour()" class="tour-icon me-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#009444" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
        </svg>
      </a>

    </div>
   </div>
 </div>

  <div class="row">
    <div class="col-12 welcome-element">
      <div class="profile">
        <div class="row">
          <div class="col-12 sub-nav text-center mt-4">
            <div class="topnav">

              <!-- Centered link -->
              <div class="topnav-centered">
                <a [ngClass]="{'active': tabIndex === 0}" (click)="onTabClick(0)"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
                  <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
                </svg></a>
                <a [ngClass]="{'active': tabIndex === 1}" (click)="onTabClick(1)"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-shield-fill-check menu-secureScore" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 0c-.69 0-1.843.265-2.928.56-1.11.3-2.229.655-2.887.87a1.54 1.54 0 0 0-1.044 1.262c-.596 4.477.787 7.795 2.465 9.99a11.777 11.777 0 0 0 2.517 2.453c.386.273.744.482 1.048.625.28.132.581.24.829.24s.548-.108.829-.24a7.159 7.159 0 0 0 1.048-.625 11.775 11.775 0 0 0 2.517-2.453c1.678-2.195 3.061-5.513 2.465-9.99a1.541 1.541 0 0 0-1.044-1.263 62.467 62.467 0 0 0-2.887-.87C9.843.266 8.69 0 8 0zm2.146 5.146a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647z"/>
                </svg></a>
                <a [ngClass]="{'active': tabIndex === 2}" (click)="onTabClick(2)"><svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="menu-price" viewBox="0 0 340.756 340.756">
                  <g>
                    <path d="M335.378,99.869c0-13.82-6.75-26.057-17.129-33.613V41.562C318.249,18.608,299.642,0,276.688,0H46.94
                      C23.986,0,5.378,18.608,5.378,41.562v58.308v141.018v58.308c0,22.954,18.608,41.561,41.562,41.561h246.877
                      c22.954,0,41.561-18.608,41.561-41.561V237.16h-49.976c-16.688,0-30.217-13.529-30.217-30.217
                      c0-16.688,13.529-30.217,30.217-30.217h49.976V99.869z M289.47,58.308H34.157V41.562c0-7.048,5.734-12.783,12.783-12.783h229.748
                      c7.05,0,12.782,5.735,12.782,12.783V58.308z"/>
                    <circle cx="285.321" cy="206.831" r="19.186"/>
                  </g>
                  </svg></a>
              </div>

            </div>
          </div>
          <div class="col-12 content-profile">
            <div *ngIf="!loginDisplay">
              <div class="d-flex mt-5 justify-content-center"><h2 class=" mt-5 brand-color fw-bolder">{{ 'profile-chart.please-login' | translate }}</h2></div>
            </div>
            <app-profile-detail  *ngIf="loginDisplay &&  tabIndex === 0"></app-profile-detail>
            <app-secure-score *ngIf="loginDisplay &&  tabIndex === 1"></app-secure-score>
            <app-price-detail [usersCount] ="usersCount" *ngIf="loginDisplay && tabIndex === 2"></app-price-detail>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
