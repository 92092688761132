import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ConsentComponent } from './component/consent/consent.component';
import { ProfileComponent } from './component/profile/profile.component';
import { WelcomeComponent } from './component/welcome/welcome.component';

const routes: Routes = [
  {path: 'welcome', component: WelcomeComponent },
  { path: 'consent', component: ConsentComponent },
  { path: 'profile', component: ProfileComponent },
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
