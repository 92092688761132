import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { SharedServiceService } from './shared-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Evolve365_SecureScore';
  radiosPanel:any = 0;
  islanguageEnglish: boolean = false;
  languageValue: string = "ENGLISH";
  currentScreen: boolean;
  constructor(private shepherdService: ShepherdService, private titleService:Title, private sharedService: SharedServiceService, private translate: TranslateService){
    if(localStorage.getItem('language') == 'de'){
      translate.setDefaultLang('de');
      translate.use('de');
      this.setTitle('EVOLVE365 Security - Prüfe deine Sicherheit')
  }else {
       translate.setDefaultLang('en');
       translate.use('en');
       localStorage.setItem("language","en");
       this.setTitle('EVOLVE365 Security - Assess your security')
  }
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  ngOnInit(): void {
    this.currentScreen = this.sharedService.getValue()
    this.sharedService.onChangePage(0);
    this.sharedService.getPage().subscribe(result => {
      this.radiosPanel = result
    })
    this.shepherTourcheck()

  }

  shepherTourcheck() {
    if (localStorage.getItem('shepherd-tour') == null) {
      localStorage.setItem('shepherd-tour', 'no');
    }
  }
  refresh(): void {
    window.location.reload();
}

  OnlanguageChange() {
    if (localStorage.getItem('language') == 'de') {
      this.translate.setDefaultLang('en');
 this.translate.use('en');
 localStorage.setItem("language","en");
 setTimeout(() => {
  this.refresh()
}, 100);
    } else {
      this.translate.setDefaultLang('de');
 this.translate.use('de');
 localStorage.setItem("language","de");
      setTimeout(() => {
        this.refresh()
      }, 100);
    }
  }
}
