// import Shepherd from 'shepherd.js/src/types/shepherd';
export const builtInButtons = {
  cancel: {
    classes: 'cancel-button',
    text: 'Exit',
    type: 'cancel',
    action() {
      // Dismiss the tour when the finish button is clicked

      dismissTour();
      return this.hide();
  }
  },
  next: {
    classes: 'next-button',
    text: 'Next',
    type: 'next'
  },
  finish: {
    classes: 'finish-button',
    text: 'Finish',
    type: 'finish'
  },
  back: {
    classes: 'back-button',
    secondary: true,
    text: 'Back',
    type: 'back'
  }
};

function  dismissTour() {
  localStorage.setItem('shepherd-tour', 'yes');
}
