<ng-template #errorAdmin let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ "signError.adminError.head" | translate }}</h4>
    <button type="button" class="close btn btn-success" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    {{ "signError.adminError.p1" | translate }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>
