import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { Subscription } from 'rxjs';
import { SharedServiceService } from 'src/app/shared-service.service';
import { builtInButtons } from '../data';
// import { tour } from 'angular-shepherd/';

const GRAPH_ENDPOINT_MFA = 'https://graph.microsoft.com/beta/users';
const GRAPH_ENDPOINT_MFA_PREMIUM ='https://graph.microsoft.com/v1.0/subscribedSkus';

@Component({
  selector: 'app-price-detail',
  templateUrl: './price-detail.component.html',
  styleUrls: ['./price-detail.component.scss']
})
export class PriceDetailComponent implements OnInit, AfterViewInit, OnDestroy {
@Input() usersCount:number;
numOfLicensedUsers:number;
isLoading: boolean = true
noOFUsers:number;
perUserMon: number;
perUserAnu: number;
totalMon: number;
totalAnu: number;
initialAmt: number = 0;
currentScreen: boolean;
public isInitialChecked:boolean;
translateSubscribe: Subscription;
steptranslate:any = {}
buttons = builtInButtons;

skuid: any = [
  'efccb6f7-5641-4e0e-bd10-b4976e1bf68e',
  'e97c048c-37a4-45fb-ab50-922fbf07a370',
  'e823ca47-49c4-46b3-b38d-ca11d5abe3d2',
  'e2be619b-b125-455f-8660-fb503e431a5d',
  'd61d61cc-f992-433f-a577-5bd016037eeb',
  'cd2925a3-5076-4233-8931-638a8c94f773',
  'cbdc14ab-d96c-4c30-b9f4-6ada7cdc1d46',
  'ca9d1dd9-dfe9-4fef-b97c-9bc1ea3c3658',
  'c793db86-5237-494e-9b11-dcd4877c2c8c,',
  'c42b9cae-ea4f-4ab7-9717-81576235ccac',
  'c2ac2ee4-9bb1-47e4-8541-d689c7e83371',
  'b05e124f-c7cc-45a0-a6aa-8cf78c946968',
  'aedfac18-56b8-45e3-969b-53edb4ba4952',
  '8a180c2b-f4cf-4d44-897c-3d32acc4a60b',
  '84a661c4-e949-4bd2-a560-ed7766fcaf2b',
  '81441ae1-0b31-4185-a6c0-32b6b84d419f',
  '7cfd9a2b-e110-4c39-bf20-c6a3f36a3121',
  '66b55226-6b4f-492c-910c-a3b7a3c9d993',
  '6070a4c8-34c6-4937-8dfb-39bbc6397a60',
  '50f60901-3181-4b75-8a2c-4c8e4c1d5a72',
  '4b590615-0888-425a-a965-b3bf7789848d',
  '46c119d4-0379-4a9d-85e4-97c66d3f909e',
  '44575883-256e-4a79-9da4-ebe9acabe2b2',
  '31d57bc7-3a05-4867-ab53-97a17835a411',
  '2a914830-d700-444a-b73c-e3f31980d833',
  '1aa94593-ca12-4254-a738-81a5972958e8',
  '18250162-5d87-4436-a834-d795c15c80f3',
  '078d2b04-f1bd-4111-bbd4-b4b1b354cef4',
  '06ebc4ee-1bb5-47dd-8120-11324bc54e06',
  '05e9a617-0261-4cee-bb44-138d3ef5d965',
];

public onCheckInitial(value:boolean){
    this.isInitialChecked = value;
    this.isInitialChecked? this.initialAmt = 50: this.initialAmt = 0
}
  constructor(private sharedService: SharedServiceService, private shepherdService: ShepherdService, private translate: TranslateService, private http: HttpClient) { }

  ngOnInit(): void {
    this.getUsers()
  }

calc(){
  this.perUserMon = this.numOfLicensedUsers * 5
this.perUserAnu = this.numOfLicensedUsers * 50
this.totalMon = (this.numOfLicensedUsers * 5) + this.initialAmt
this.totalAnu = (this.numOfLicensedUsers * 5) + this.initialAmt
this.isLoading =false
}

ngAfterContentInit(){
  this.getTranslateNav()
    }
ngAfterViewInit() {

  setTimeout(() => {
    this.addShepherd ();
}, 200);
}

getTranslateNav() {
  this.translateSubscribe = this.translate
      .stream('button.exit')
      .subscribe((text: string) => {
        this.buttons.cancel.text = text;
      });
      this.translateSubscribe = this.translate
      .stream('button.next')
      .subscribe((text: string) => {
        this.buttons.next.text = text;
      });
      this.translateSubscribe = this.translate
      .stream('button.finish')
      .subscribe((text: string) => {
        this.buttons.finish.text = text;
      });
  this.translateSubscribe = this.translate
    .stream("tour.title-13")
    .subscribe((text: string) => {
      this.steptranslate.title1 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tour.text-13")
    .subscribe((text: string) => {
      this.steptranslate.text1 = text;
    });

    this.translateSubscribe = this.translate
    .stream("tourIcon.title")
    .subscribe((text: string) => {
      this.steptranslate.title2 = text;
    });
    this.translateSubscribe = this.translate
    .stream("tourIcon.text")
    .subscribe((text: string) => {
      this.steptranslate.text2 = text;
    });
  }



addShepherd(): void {
  function dismissTour() {
    localStorage.setItem('shepherd-tour', 'yes');
  }

 this.shepherdService.addSteps([
    {
      id: 'step15',
      attachTo: {
        element: '',
        on: 'auto'
      },
      buttons: [
        builtInButtons.cancel, builtInButtons.next],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      title: this.steptranslate.title1,
      text: [`${this.steptranslate.text1}`],
      // text: [`${this.steptranslate.text1}\n${this.steptranslate.text2}\n${this.steptranslate.text3}\n${this.steptranslate.text4}`],
      when: {
        show: () => {},
        hide: () => {}
      }
    },
    {
      id: 'step16',
      attachTo: {
        element: '.tour-icon',
        on: 'auto'
      },
      buttons: [
        {
          text: this.buttons.finish.text,
          classes: 'shepherd-button-close',
          action() {
              // Dismiss the tour when the finish button is clicked
              function dismissTour() {
                localStorage.setItem('shepherd-tour', 'yes');
              }
              dismissTour();
              return this.hide();
          }
      }
      ],
      classes: 'custom-class-name-1 custom-class-name-2',
      highlightClass: 'highlight',
      scrollTo: false,
      title: this.steptranslate.title2,
      // text: [this.steptranslate.text2],
      text: [this.steptranslate.text2],
      when: {
        show: () => {},
        hide: () => {}
      }
    },


    ]);
    this.tourStart();
};

// Dismiss the tour when the cancel icon is clicked. Do not show the tour on next page reload

// Initiate the tour
tourStart(){
  if (localStorage.getItem('shepherd-tour') == 'no') {
    this.shepherdService.start();
  }
}

getUsers() {
  this.http.get<any>(GRAPH_ENDPOINT_MFA).subscribe({
    next: (response) => {
      let usersValue
      usersValue = response.value;
      this.getPremiumUsers();
      this.currentScreen = this.sharedService.getValue()
    },
    error: (error) =>
    error
  });
}

getPremiumUsers(){
  this.http.get<any>(GRAPH_ENDPOINT_MFA_PREMIUM).subscribe({
    next: (response) => {
      let usersValue;
      usersValue = response.value;
      this.numOfLicensedUsers = this.getLicensedUsersCount(usersValue)
      this.calc();
      console.log('loaded')
    },
    error: (error) =>
    error
  });
}

getLicensedUsersCount(users: any): number {
  let count:number = 0;
  this.skuid.forEach((skuid) => {
    users.forEach((plan) => {
      if (plan.skuId === skuid) {
        if (plan.capabilityStatus === 'Enabled') {
          count = count + plan.consumedUnits
        }
      }
    });
  });
 return count;
}

ngOnDestroy(): void {
    this.shepherdService.cancel()
}


}
